<template>
  <tr>
    <td v-for="column in columns" v-bind:key="column">
      <p>
        <input
          class="input"
          type="text"
          v-model="likes[columns.indexOf(column)]"
          v-bind:placeholder="`search ${column}`"
        />
      </p>
      <p>{{ answer }}</p>
      <!-- </td>
    selection column 
    <td />-->
    </td>
  </tr>
</template>
<script src="https://cdn.jsdelivr.net/npm/lodash@4.13.1/lodash.min.js"></script>
<script>
import _ from "lodash";

export default {
  name: "Search",
  props: {
    callback: Function,
    columns: Array,
    likes: Array,
    changePage: Function,
  },
  data: function () {
    return {
      answer: "",
    };
  },
  watch: {
    likes: function (newQuestion, oldQuestion) {
      this.answer = "Waiting for you stop typing ...";
      this.debouncedGetAnswer();
    },
  },
  created: function () {
    this.debouncedGetAnswer = _.debounce(this.getAnswer, 500);
  },
  methods: {
    getAnswer: function () {
      this.answer = "Thinking ...";
      var vm = this;
      this.changePage(1);
      this.callback()
        .then(function () {
          vm.answer = "";
        })
        .catch(function (error) {
          vm.answer = `Error! could not reach the API. ` + error;
        });
    },
  },
};
</script>