import axios from "axios";
const API_URL = "/backend/measure";
import authHeader from "../services/authHeader";

class MeasureService {
  async getDocument(parameters) {
    const response = await axios.get(`${API_URL}/document`, {
      params: parameters,
      headers: authHeader()
    });
    return response.data;
  }
  async countDocument(parameters) {
    const response = await axios.get(`${API_URL}/document/count`, {
      params: parameters,
      headers: authHeader()
    });
    return response.data;
  }
  async getDocumentById(id) {
    const response = await axios.get(`${API_URL}/documentById/${id}`, {
      headers: authHeader()
    });
    return response.data;
  }
  async getMeasureTypeByIdDocument(idDocument) {
    const response = await axios.get(
      `${API_URL}/measureTypeByidDocument/${idDocument}`,
      { headers: authHeader() }
    );
    return response.data;
  }
  async getMeasureType() {
    const response = await axios.get(`${API_URL}/measureType`, {
      headers: authHeader()
    });
    return response.data;
  }
  async getMeasureTypeForAnalysePageById(parameters) {
    let intialTime = (new Date).getTime();
    console.log("start /MeasureTypeForAnalysePageById ");
    const response = await axios.get(`${API_URL}/MeasureTypeForAnalysePageById`, {
      params: parameters,
      headers: authHeader()
    });
    let endTime = (new Date).getTime();
    let seconde = endTime-intialTime;
    console.log("time for MeasureTypeForAnalysePageById " + seconde )
    return response.data;
  }

  async getMeasureTypeForAnalysePageByDocumentId(parameters){
    const response = await axios.get(`${API_URL}/measureTypeForAnalysePageByDocumentId`, {
      params: parameters,
      headers: authHeader()
    });
    return response.data;

  }

  async getMeasureTypeById(parameters) {
    const response = await axios.get(`${API_URL}/measureTypeById`, {
      params: parameters,
      headers: authHeader()
    });
    return response.data;
  }

  async addTypeToDocument(info) {
    const response = await axios
      .post(`${API_URL}/addTypeInDocument`, info, { headers: authHeader() })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          //console.log(error.response.data);
          return Promise.reject(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } // else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        //console.log(error.request);
        // } else {
        // Something happened in setting up the request that triggered an Error
        //console.log('Error', error.message);
        //}
      });
    return response.data;
  }

  async process(parameters) {
    const response = await axios
      .post(`${API_URL}/process`, parameters, { headers: authHeader() })
      .catch(function (error) {
        if (error.response) {
          return Promise.reject(error.response.data);
        }
      });
    return response.data;
  }

  async documentremovetype(info) {
    const response = await axios.patch(`${API_URL}/documentType`, info, {
      headers: authHeader()
    });
    return response.data;
  }

  async addMeasureType(info) {
    const response = await axios
      .post(`${API_URL}/measureType/`, info, { headers: authHeader() })
      .catch(function (error) {
        if (error.response) {
          return Promise.reject(error.response.data);
        }
      });
    return response.data;
  }
  // async getScrips() {
  //     const response = await axios.get(`${API_URL}/scripts`, { headers: authHeader() });
  //     return response.data;
  // }
  async addScriptPath(info) {
    const response = await axios.post(`${API_URL}/scriptPath`, info, {
      headers: authHeader()
    });
    return response.data;
  }
  async createResult(info) {
    const response = await axios.post(`${API_URL}/result`, info, {
      headers: authHeader()
    });
    return response.data;
  }
  async getTransfoScript() {
    const response = await axios.get(`${API_URL}/transfoScript`, {
      headers: authHeader()
    });
    return response.data;
  }
  async getmeasureTypeByInstrument(instrumentId) {
    const response = await axios.get(
      `${API_URL}/instrument/measureType/${instrumentId}`,
      { headers: authHeader() }
    );
    return response.data;
  }
  async getResults() {
    const response = await axios.get(`${API_URL}/result`, {
      headers: authHeader()
    });
    return response.data;
  }
  async getAllInstrument() {
    const response = await axios.get(`${API_URL}/instrument`, {
      headers: authHeader()
    });
    return response.data;
  }
  async linMeasureInstrument(info) {
    const response = await axios.patch(`${API_URL}/instrument/link`, info, {
      headers: authHeader()
    });
    return response.data;
  }
  async linMeasureScript(info) {
    const response = await axios.post(`${API_URL}/script/link`, info, {
      headers: authHeader()
    });
    return response.data;
  }

  async getPreviewParams(parameters) {
    let intialTime = (new Date()).getTime();
    console.log("start request /preview/params")
    const response = await axios.get(`${API_URL}/preview/params`, {
      params: parameters,
      headers: authHeader()
    });
    let endTime = ( new Date()).getTime();
    let milis = endTime - intialTime;
    console.log("time to get the request /preview/params" +milis)
    return response.data;
  }

  async save(parameters) {
    const response = await axios.post(`${API_URL}/save`, parameters, {
      headers: authHeader()
    });
    return response.data;
  }

  async delete(parameters) {
    const response = await axios.post(`${API_URL}/delete`, parameters, {
      headers: authHeader()
    });
    return response.data;
  }

  async getPreview(parameters) {
    const response = await axios.get(`${API_URL}/preview`, {
      params: parameters,
      headers: authHeader()
    });
    return response.data;
  }
  async getAllMeasures(like) {
    const response = await axios.get(`${API_URL}/saved`, {
      params: like,
      headers: authHeader()
    });
    return response.data;
  }
  async forceReaload() {
    axios.get(`${API_URL}/forceReload`, { headers: authHeader() });
  }

  async getAllDatas(params) {
    let intialTime = (new Date()).getTime();
    console.log("start /getData")
    const response = await axios.get(`${API_URL}/getData`, {
      params: params,
      headers: authHeader()
    });
    let endTime = (new Date()).getTime();
    let milli = endTime - intialTime;
    console.log("time for /getData : " + milli )
    return response.data;
  }
  async getAllScript() {
    const response = await axios.get(`${API_URL}/script`, {
      headers: authHeader()
    });
    return response.data;
  }

  async getAxis(params) {
    let intialTime = (new Date()).getTime();
    console.log("start /axis")

    const response = await axios.get(`${API_URL}/axis`, {
      params: params,
      headers: authHeader()
    });
    let endTime = (new Date()).getTime();
    let milli = endTime - intialTime;
    console.log("time for /axis : " + milli )
    return response.data;
  }

  async getProcess(params) {
    const response = await axios
      .get(`${API_URL}/process`, {
        params: params,
        headers: authHeader()
      })
      .catch(function (error) {
        if (error.response) {
          return Promise.reject(error.response.data);
        }
      });
    return response.data;
  }
}

export default new MeasureService();
