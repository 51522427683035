<template>
  <div class="columns is-multiline">
    <div
      class="column"
      v-for="(pandas_Dataframe, index) in pandas_Dataframes"
      v-bind:key="index"
    >
      <uniqueTable
        v-if="
          !pandas_Dataframe.columns[0].match(/RANGE-/g) &&
          !pandas_Dataframe.columns[0].toLowerCase().match(/id/g)
        "
        v-bind:pandas_Dataframe="pandas_Dataframes[index]"
        v-bind:changeSelectedData="selectFunction(index)"
      />
      <div v-else-if="!pandas_Dataframe.columns[0].toLowerCase().match(/id/g)">
        <rangeFilterTable
          v-bind:name="pandas_Dataframe.columns[0]"
          v-bind:minValue="pandas_Dataframe.data[0][0]"
          v-bind:maxValue="pandas_Dataframe.data[1][0]"
          v-bind:applyFunction="setMinMax"
          v-bind:index="index"
        />
      </div>
    </div>
  </div>
</template>

<script>
import uniqueTable from "./uniqueTable";
import rangeFilterTable from "./rangeFilterTable";

export default {
  name: "filtre",
  components: {
    uniqueTable,
    rangeFilterTable,
  },
  props: {
    pandas_Dataframes: Array,
    changeSelection: Function,
  },
  data: function () {
    return {
      selected: [],
      selected_range: [],
      changingFunctions: [],
    };
  },
  created: function () {
    console.log("create filter");
    console.log(this.pandas_Dataframe);
  },

  methods: {
    setMinMax(index, min, max) {
      console.log("start a min max");
      let startTime = new Date().getTime();
      var selection = ["RANGE"];

      selection.push(min);
      selection.push(max);
      this.change(selection, index);
      let endTime = new Date().getTime();
      let millis = endTime - startTime;
      console.log("end min max " + millis);
    },
    change(selection, index) {
      console.log("changing index " + index);
      console.log(selection);
      this.selected[index] = selection;
      console.log(this.selected);
      this.changeSelection(this.selected);
    },
    selectFunction(index) {
      return this.changingFunctions[index];
    },
  },
  watch: {
    pandas_Dataframes: {
      // eslint-disable-next-line
      handler(newDf, oldDf) {
        console.log("start handler pandas_Dataframes in filter");
        console.log(newDf);
        let startTime = new Date().getTime();
        var change = this.change;
        for (const index in this.pandas_Dataframes) {
          this.selected.push([]);
          this.changingFunctions.push(function (selection) {
            change(selection, index);
          });
        }
        let endTime = new Date().getTime();
        let millis = endTime - startTime;
        console.log("end of handler in filter : " + millis);
      },
      immediate: true,
    },
  },
};
</script>