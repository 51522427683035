<template>
  <div class="measureType">
    <div class="columns is-mobile is-centered is-vcentered">
      <div class="column" v-if="loading == -1">
        <button class="button is-danger is-small is-loading">Loading</button>
      </div>
      <div
        class="column"
        v-else
        v-for="type in measureTypes"
        v-bind:key="type.id"
      >
        <div class="block">
          <button
            class="delete is-small"
            @click="del(type.id)"
            style="position: relative; left: 80px; top: 5px"
          ></button>
          <span class="button is-success" @click="preview(type.name, type.id)">
            {{ type.name }}
          </span>
        </div>
      </div>
      <div class="column" v-if="loading == 1">
        <button class="button is-success is-small is-loading">Loading</button>
      </div>

      <!-- <div class="column" v-if="!addtype">
        <button class="button is-small is-success" @click="activateAddType()">
          +
        </button>
      </div> -->
      <div class="column">
        <div class="select is-primary">
          <select v-model="selected" @change="addOption(selected)">
            <option disabled value>add</option>
            <option
              v-for="PossibleMeasureType in PossibleMeasureTypes"
              v-bind:key="PossibleMeasureType.text"
              v-bind:value="PossibleMeasureType.value"
            >
              {{ PossibleMeasureType.text }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
var Mutex = require("async-mutex").Mutex;

export default {
  name: "MeasureType",
  props: {
    documentId: Number,
    instrumentId: Number,
  },
  data: function () {
    return {
      measureTypes: {},
      selected: "",
      PossibleMeasureTypes: [],
      loading: 0,
      mutex: new Mutex(),
    };
  },
  created: function () {
    this.getTypes(this.documentId);
    this.getPossibleType(this.instrumentId);
    // this.activateAddType();
  },
  methods: {
    preview(typeName, typeId) {
      
      this.$router.push({
        name: "preview",
        params: {
          id: typeId,
          idAnalyse: -1,
          documentId : this.documentId,
        },
      });
    },
    async getPossibleType(instrumentId) {
      //need to protect this part from concurency
      const release = await this.mutex.acquire();
      this.PossibleMeasureTypes = [];
      await this.$store
        .dispatch("measures/getmeasureTypeByInstrument", instrumentId)
        .then((PossibleMeasureTypes) => {
          for (const PossibleMeasureType of PossibleMeasureTypes) {
            this.PossibleMeasureTypes.push({
              text: PossibleMeasureType.measureName,
              value: PossibleMeasureType.idMeasureType,
            });
          }
        });
      release();
    },
    async getTypes(documentId) {
      await this.$store
        .dispatch("measures/getMeasureTypeByIdDocument", documentId)
        .then((measureTypes) => {
          this.measureTypes = measureTypes;
        });
    },

    async addOption(selected) {
      this.loading = 1;
      this.$store
        .dispatch("measures/addTypeToDocument", {
          documentId: this.documentId,
          typeId: selected,
        })
        .then(
          () => {
            this.loading = 0;
            this.getTypes(this.documentId);
          },
          (err) => {
            this.loading = 0;
            this.selected = "";
            this.$fire({
              title: "something went wrong :",
              text: err.error,
              type: "error",
            });
          }
        );
    },
    del(typeId) {
      this.$confirm(
        "if you delete a value contained in an analysis, this information will be lost, there is no way back.",
        "some analysis can not work after this modification",
        "question"
      ).then(() => {
        this.selected = "";
        this.loading = -1;
        this.$store
          .dispatch("measures/documentremovetype", {
            documentId: this.documentId,
            typeId: typeId,
            instrumentId: this.instrumentId,
          })
          .then(() => {
            this.loading = 0;
            this.getTypes(this.documentId);
          });
      });
    },
  },
};
</script>
