<template>
  <section class="hero">
    <div class="hero-body">
      <h1 class="title">Analyses</h1>
      <h2 class="subtitle">here you can find all the analyses saved</h2>
      <table
        class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
      >
        <thead>
          <th
            v-for="column_name in displayableColumns()"
            v-bind:key="column_name"
          >
            {{ column_name }}
          </th>
          <th>go to analysis</th>
        </thead>
        <tbody>
          <Search
            v-bind:callback="getMeasures"
            v-bind:columns="displayableColumns()"
            v-bind:likes="likes"
            v-bind:changePage="changePage"
          />
          <!-- v-bind:changePage="changePage" -->
          <tr v-for="row in data" v-bind:key="row">
            <td v-for="index in displayableIndexOfColumns()" v-bind:key="index">
              {{ row[index] }}
            </td>
            <td>
              <button class="button" @click="gotoRow(row)">
                go to analyse
              </button>
            </td>
            <td>
              <a class="delete" @click="deleteAnalyse(row)"></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div></div>
  </section>
</template>



<script>
import Search from "../components/search";
export default {
  name: "measure",
  components: {
    Search,
  },
  data: function () {
    return {
      ignoreColumns: [
        "Xaxes",
        "Yaxes",
        "Categorie",
        "id",
        "instrumentID",
        "measuretypeID",
      ],
      data: [],
      columns: [],
      likes: [],
    };
  },
  created() {
    this.getMeasures();
  },

  methods: {
    deleteAnalyse(row) {
      this.$store
        .dispatch("measures/delete", {
          name: row[this.columns.indexOf("name")],
          instrumentID: row[this.columns.indexOf("instrumentID")],
          idmeasureType: row[this.columns.indexOf("measuretypeID")],
        })
        .then(() => {
          this.data = [];
          this.getMeasures();
        });
    },
    displayableColumns() {
      let results = [];
      for (const column of this.columns) {
        if (this.ignoreColumns.indexOf(column) == -1) {
          results.push(column);
        }
      }
      return results;
    },
    displayableIndexOfColumns() {
      let results = [];
      let index = 0;
      for (const column of this.columns) {
        if (this.ignoreColumns.indexOf(column) == -1) {
          results.push(index);
        }
        index += 1;
      }
      return results;
    },

    // eslint-disable-next-line
    changePage() {},
    gotoRow(row) {
      this.$router.push({
        name: "preview",
        params: {
          id: row[this.columns.indexOf("measuretypeID")],
          idAnalyse: row[this.columns.indexOf("id")],
        },
      });
    },
    async getMeasures() {
      let liking = {};
      let displayableColumn = this.displayableColumns();
      for (const index in this.likes) {
        liking[displayableColumn[index]] = this.likes[index];
      }
      this.$store
        .dispatch("measures/getAllMeasures", { like: liking })
        .then((measures) => {
          this.columns = Object.getOwnPropertyNames(measures[0]);
          this.data = [];
          for (let measure of measures) {
            let predata = [];
            for (let column of this.columns) {
              predata.push(measure[column]);
            }
            this.data.push(predata);
          }
        });
    },
    // eslint-disable-next-line
    changePage(page) {
      /*no page for the moment*/
    },
  },
};
</script>