<template>
  <div>
    <DocumentTable />
  </div>
</template>

<script>
import DocumentTable from "./DocumentTable";

export default {
  name: "Home",
  components: {
    DocumentTable,
  },
  data: function () {
    return {
      columnsName: [],
      completes: [],
    };
  },
  props: {
    msg: String,
  },
};
</script>
