<template>
  <div id="app">
    <div v-if="this.loggedIn">
      <theNavBarComponent />
    </div>
    <router-view />
  </div>
</template>

<script>
import theNavBarComponent from "@/components/TheNavBarComponents";
export default {
  name: "app",
  components: {
    theNavBarComponent,
  },
  computed: {
    loggedIn() {
     // this.$store.dispatch("auth/forceTesting");
      return this.$store.state.auth.status.loggedIn;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
</style>