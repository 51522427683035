import axios from "axios";
const API_URL = "/backend";

const LastUser = JSON.parse(localStorage.getItem("user"));

var initialState = { status: { loggedIn: false }, user: null };

if (LastUser != undefined) {
    axios.get(`${API_URL}/connectionTest`, { headers: { Authorization: LastUser.token } }).then(
        () => {
            initialState.status.loggedIn = true;
            initialState.user = LastUser;
        }
    ).catch()
}

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        forceTesting({ commit }) {
            commit("loginSucess", { 'Ad': "CARFULL NO LOG ACTIVATE" })
        },
        alreadyLog({ commit }, user) {
            commit("loginSucess", user);
        },

        login({ commit }, googleUser) {
            var id_token = googleUser.getAuthResponse().id_token;
            let user = googleUser.getBasicProfile();
            user.token = id_token;
            commit("loginSucess", user);
        },
        logout({ commit }) {
            commit("logout");
        }
    },
    mutations: {
        loginSucess(state, user) {
            localStorage.setItem("user", JSON.stringify(user))
            state.status.loggedIn = true;
            state.user = user;
        },
        logout(state) {
            localStorage.removeItem("user");
            state.status.loggedIn = false;
            state.user = null;
        }
    }
}