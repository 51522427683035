import MeasureService from "../services/measure.service";

export const measures = {
  namespaced: true,
  state: {
    measureOperationIsFinish: false
  },
  actions: {
    getDocument({ commit }, params) {
      return MeasureService.getDocument(params).then(
        (Documents) => {
          commit("getDocumentSucess", Documents);
          return Promise.resolve(Documents);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
    },
    // eslint-disable-next-line
    countDocument({ commit }, params) {
      return MeasureService.countDocument(params).then(
        (count) => {
          return Promise.resolve(count[0]);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
    },

    getDocumentById({ commit }, id) {
      return MeasureService.getDocumentById(id).then(
        (document) => {
          commit("getDocumentByIdSucess", document[0]);
          return Promise.resolve(document[0]);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
    },
    // eslint-disable-next-line
    getMeasureTypeByIdDocument({ commit }, idDocument) {
      return MeasureService.getMeasureTypeByIdDocument(idDocument).then(
        (measureTypes) => {
          return Promise.resolve(measureTypes);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
    },
    // eslint-disable-next-line
    getMeasureType({ commit }) {
      return MeasureService.getMeasureType().then(
        (measureTypes) => {
          return Promise.resolve(measureTypes[0]);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
    },
    // eslint-disable-next-line
    addTypeToDocument({ commit }, info) {
      return MeasureService.addTypeToDocument(info);
    },
    // eslint-disable-next-line
    documentremovetype({ commit }, info) {
      return MeasureService.documentremovetype(info);
    },
    // eslint-disable-next-line
    addMeasureType({ commit }, info) {
      return MeasureService.addMeasureType(info);
    },
    // eslint-disable-next-line
    addScriptPath({ commit }, info) {
      return MeasureService.addScriptPath(info);
    },
    // eslint-disable-next-line
    createResult({ commit }, info) {
      return MeasureService.createResult(info).then(
        (resultId) => {
          commit("createResultsSucess");
          return Promise.resolve(resultId);
        },
        () => {
        }
      );
    },
    // eslint-disable-next-line
    getResults({ commit }) {
      return MeasureService.getResults();
    },
    // eslint-disable-next-line
    getMeasureTypeForAnalysePageById({ commit }, parameters) {
      return MeasureService.getMeasureTypeForAnalysePageById(parameters);
    },
    // eslint-disable-next-line
    getMeasureTypeForAnalysePageByDocumentId({commit}, parameters){
      return MeasureService.getMeasureTypeForAnalysePageByDocumentId(parameters);
    },
    // eslint-disable-next-line
    getTransfoScript({ commit }) {
      return MeasureService.getTransfoScript();
    },
    // eslint-disable-next-line
    async getmeasureTypeByInstrument({ commit }, instrumentId) {
      const possibleMeasureType = await MeasureService.getmeasureTypeByInstrument(
        instrumentId
      );
      return Promise.resolve(possibleMeasureType);
    },
    // eslint-disable-next-line
    async getAllInstrument({ commit }) {
      const instruments = await MeasureService.getAllInstrument();
      return Promise.resolve(instruments);
    },
    // eslint-disable-next-line
    linMeasureInstrument({ commit }, info) {
      return MeasureService.linMeasureInstrument(info);
    },
    // eslint-disable-next-line
    linMeasureScript({ commit }, info) {
      return MeasureService.linMeasureScript(info);
    },
    // eslint-disable-next-line
    async getPreviewParams({ commit }, params) {
      return MeasureService.getPreviewParams(params);
    },
    // eslint-disable-next-line
    async getPreview({ commit }, params) {
      return MeasureService.getPreview(params);
    },
    // eslint-disable-next-line
    async save({ commit }, params) {
      return MeasureService.save(params);
    },
    // eslint-disable-next-line
    async delete({ commit }, params) {
      return MeasureService.delete(params)
    },
    // eslint-disable-next-line
    async getAllMeasures({ commit }, liking) {
      return MeasureService.getAllMeasures(liking);
    },
    // eslint-disable-next-line
    async forceReaload({ commit }) {
      return MeasureService.forceReaload();
    },
    // eslint-disable-next-line
    async getAllDatas({ commit }, params) {
      return MeasureService.getAllDatas(params);
    },
    // eslint-disable-next-line
    async process({ commit }, params) {
      return MeasureService.process(params);
    },
    // eslint-disable-next-line
    async getAllScript({ commit }) {
      return MeasureService.getAllScript();
    },
    // eslint-disable-next-line
    async getMeasureTypeById({ commit }, params) {
      return MeasureService.getMeasureTypeById(params);
    },
    // eslint-disable-next-line
    async getAxis({ commit }, params) {
      return MeasureService.getAxis(params);
    },
    // eslint-disable-next-line
    async getProcess({ commit }, params) {
      return MeasureService.getProcess(params);
    }
  },
  mutations: {
    getDocumentSucess(state, Documents) {
      state.Documents = Documents;
    },
    getDocumentByIdSucess(state, document) {
      state.document = document;
    },
    createResultsSucess(state) {
      state.measureOperationIsFinish = !state.measureOperationIsFinish;
    }
  }
};
