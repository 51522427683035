import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSimpleAlert from "vue-simple-alert";
 
Vue.use(VueSimpleAlert);

//import bulma
require("./assets/main.scss");

//font-awesome
import { library } from "@fortawesome/fontawesome-svg-core";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faCaretDown,
  faCaretUp,

} from "@fortawesome/free-solid-svg-icons";

library.add(faCaretDown, faCaretUp);

Vue.component("font-awesome-icon", FontAwesomeIcon);


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
