<template>
  <div class="home">
        <div v-if="this.loggedIn">

    <!-- <div v-if="true"> -->
      <HomeComponent />
    </div>
    <div v-else class="mt-4">
      <section class="hero is-fullheight-with-navbar">
        <div class="hero-body">
          <div class="container">
            <h1 class="title">measurement platform</h1>
            <h2 class="subtitle">
              only h.glass's employees can acces to this application, connect
              with you're google company account. if you not an employees please
              visit <a href="https://h.glass/"> us website</a>
            </h2>
            <div class="columns is-mobile is-centered">
              <div class="column is-narrow">
                <GoogleLogin
                  :params="params"
                  :renderParams="renderParams"
                  :onSuccess="onSuccess"
                  :onFailure="onFailure"
                ></GoogleLogin>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>




<script>
// @ is an alias to /src
import HomeComponent from "@/components/Home.vue";
import GoogleLogin from "vue-google-login";

export default {
  name: "Home",
  data: function () {
    return {
      // client_id is the only required property but you can add several more params, full list down bellow on the Auth api section
      params: {
        client_id:
          "632923064912-bju0nv9eeb36cajqcaa7co0c26ebk2ga.apps.googleusercontent.com",
      },
      // only needed if you want to render the button with the google ui
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
    };
  },
  components: {
    HomeComponent,
    GoogleLogin,
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.user = this.$store.state.auth.user;
    }
  },
  methods: {
    onSuccess(googleUser) {
      this.$store.dispatch("auth/login", googleUser);
    },
    onFailure() {
      alert(
        "Sorry you don't have acess to this website, you will be redirected to us website."
      );
      window.location.href = "https://h.glass";
    },
  },
};
</script>
