<template>
  <div id="graph" width="1260" height="600"></div>
</template>

<script>
var d3 = require("d3");
const utils = require("../services/utils.service");

export default {
  name: "ValueOverTime",
  props: {
    pandas_Dataframe: Object,
    axes: Object,
    title: String,
  },
  data: function () {
    return {
      margin: { left: 100, bottom: 100, right: 60, top: 50 },
      height: 477,
      chartWidth: 1000,
      chartHeight: 500,
    };
  },
  created: function () {
    this.SafeRender();
  },
  methods: {
    async SafeRender() {
      console.log("start safe render");
      const propOwn = Object.getOwnPropertyNames(this.pandas_Dataframe);
      const propOwnAxes = Object.getOwnPropertyNames(this.axes);
      if (propOwn.length > 0 && propOwnAxes.length > 0) {
        this.render();
      }
    },

    async render() {

      console.log("start render");
      let intialTime = new Date().getTime();
      d3.select("#graph").selectAll("svg").remove();
      let indexX = this.pandas_Dataframe.columns.indexOf(this.axes["axe_x"]);
      let indexY = this.pandas_Dataframe.columns.indexOf(this.axes["axe_y"]);
      let indexCategorie = this.pandas_Dataframe.columns.indexOf(
        this.axes["categorie"]
      );
      console.log(indexX);
      console.log(indexY);
      console.log(indexCategorie);

      let yData = [];
      let xData = [];
      let categorieData = [];
      for (const measure of this.pandas_Dataframe.data) {
        if (measure[indexX] != null && measure[indexY] != null) {
          xData.push(measure[indexX]);
          categorieData.push(measure[indexCategorie]);
          yData.push(measure[indexY]);
        }
      }

      //scale x & generator x
      let Xvalue = await this.axisGenerators(
        this.axes["axe_x"],
        xData,
        [0, this.chartWidth],
        true
      ); //javascript don't support multiple return -- need refractor for code beautify
      let xAxisGenerator = Xvalue[0];
      let xScale = Xvalue[1];
      this.xScale = xScale;

      //scale Y & generator Y
      let Yvalue = await this.axisGenerators(
        this.axes["axe_y"],
        yData,
        [this.chartHeight, 0],
        false
      ); //javascript don't support multiple return -- need refractor for code beautify
      let yAxisGenerator = Yvalue[0];
      let yScale = Yvalue[1];
      this.yScale = yScale;

      // eslint-disable-next-line
      const lineGenerator = d3
        .line()
        .x((d) => {
          if (this.axes["axe_x"].toLowerCase().match(/date/g) != null) {
            return this.xScale(new Date(d.x));
          }
          return this.xScale(d.x);
        })
        .y((d) => {
          if (this.axes["axe_y"].toLowerCase().match(/date/g) != null) {
            return this.yScale(new Date(d.y));
          }
          return this.yScale(d.y);
        });
      let categories = [];
      let newlineData = [];
      let nbrColors = 0;

      for (let i = 0; i < xData.length; i++) {
        if (!categories.includes(categorieData[i])) {
          categories.push(categorieData[i]);
          nbrColors += 2;
          newlineData.push({
            key: categorieData[i],
            light: "#fb9a99",
            dark: "#e31a1c",
            data: [],
          });
        }
        for (let line of newlineData) {
          if (line.key == categorieData[i]) {
            line.data.push({ x: xData[i], y: yData[i] });
          }
        }
      }

      //color
      var ordinalScale = d3
        .scaleOrdinal()
        .domain([0, nbrColors + 1])
        .range(d3.schemePaired);

      let currentColor = 1;
      for (let cat of newlineData) {
        cat.light = ordinalScale(currentColor);
        cat.dark = ordinalScale(currentColor + 1);
        currentColor += 2;
      }

      // sort by axe x
      for (let line of newlineData) {
        if (this.axes["axe_x"].toLowerCase().match(/date/g) != null) {
          line.data.sort((a, b) => {
            return new Date(a.x) > new Date(b.x) ? 1 : -1;
          });
        } else {
          line.data.sort((a, b) => (a.x > b.x ? 1 : -1));
        }
        line.data.sort();
      }
      // eslint-disable-next-line
      const chartHeight = this.chartHeight;
      // eslint-disable-next-line
      const chartWidth = this.chartWidth;

      const svg = d3
        .select("#graph")
        .append("svg")
        .attr("id", "svg")
        .attr("width", this.chartWidth + this.margin.left + this.margin.right)
        .attr("height", this.height + this.margin.top + this.margin.bottom)
        .attr(
          "transform",
          "translate(" + this.margin.left + "," + this.margin.top + ")"
        );

      const g = svg
        .append("g")
        .attr(
          "transform",
          `translate(${this.margin.left}, ${this.margin.top})`
        );
      g.append("g")
        .call(xAxisGenerator)
        .attr("transform", `translate(0, ${chartHeight})`);

      g.append("g")
        .call(yAxisGenerator)
        .selectAll("text")
        .attr("transform", "rotate(45)");

      g.selectAll(".line")
        .data(newlineData)
        .enter()
        .append("path")
        .attr("d", (d) => lineGenerator(d.data))
        .style("fill", "none")
        .style("stroke", (d) => d.light)
        .style("stroke-width", 2)
        .style("stroke-linejoin", "round");

      const valueLabel = g
        .selectAll(".label")
        .data(newlineData)
        .enter()
        .append("g")
        .attr(
          "transform",
          (d) =>
            `translate(
              ${
                this.axes["axe_x"].toLowerCase().match(/date/g) == null
                  ? this.xScale(this.last(d.data).x)
                  : this.xScale(new Date(this.last(d.data).x))
              }
              , ${
                this.axes["axe_y"].toLowerCase().match(/date/g) == null
                  ? this.yScale(this.last(d.data).y)
                  : this.yScale(new Date(this.last(d.data).y))
              })`
        );

      valueLabel
        .append("circle")
        .attr("r", 4)
        .style("stroke", "white")
        .style("fill", (d) => d.light);

      valueLabel
        .append("text")
        .text((d) => d.key)
        .attr("dy", 20)
        .attr("dx", -40)
        .style("font-family", "monospace")
        .style("fill", (d) => d.dark);

      svg
        .append("text")
        .attr("x", chartWidth / 2)
        .attr("y", this.margin.top / 2)
        .attr("text-anchor", "middle")
        .style("font-size", "16px")
        .style("text-decoration", "underline")
        .text(`${this.title}`);

      svg
        .append("text")
        .attr("class", "x label")
        .attr("text-anchor", "end")
        .attr("x", chartWidth)
        .attr("y", chartHeight)
        .text(`${this.axes["axe_x"]}`);

      svg
        .append("text")
        .attr("class", "y label")
        .attr("text-anchor", "end")
        .attr("x", 0)
        .attr("y", 80)
        .attr("transform", "rotate(-90)")
        .text(`${this.axes["axe_y"]}`);

      let endTime = new Date().getTime();
      let millis = endTime - intialTime;
      console.log("time to render graph" + millis);
    },
    last(array) {
      return array[array.length - 1];
    },

    axisGenerators(axis, data, range, bottom) {
      if (axis.toLowerCase().match(/date/g) != null) {
        let StartDate = new Date(d3.min(data));
        let endDate = new Date(d3.max(data));
        let percentileDate = (endDate - StartDate) / 10;
        StartDate = new Date(StartDate.getTime() - percentileDate);
        endDate = new Date(endDate.getTime() + percentileDate);
        let scale = d3.scaleTime().domain([StartDate, endDate]).range(range);

        //generator
        let generator = null;
        if (bottom) {
          generator = d3
            .axisBottom(scale)
            .tickValues(
              d3
                .range(0, 5)
                .map(
                  (d) =>
                    new Date(
                      (d * (endDate - StartDate)) / 5 + StartDate.getTime()
                    )
                )
            )
            .tickFormat((d) => utils.TOString(new Date(d)));
        } else {
          generator = d3
            .axisLeft(scale)
            .tickValues(
              d3
                .range(0, 5)
                .map(
                  (d) =>
                    new Date(
                      (d * (endDate - StartDate)) / 5 + StartDate.getTime()
                    )
                )
            )
            .tickFormat((d) => utils.TOString(new Date(d)));
        }

        return [generator, scale];
      } else if (typeof d3.min(data) == "string") {
        data = data.sort();
        let percentile = (range[0] - range[1]) / 10;
        range[0] -= percentile;
        range[1] += percentile;
        let scale = d3.scalePoint().domain(data).range(range);
        let generator = null;
        if (bottom) {
          generator = d3.axisBottom(scale).tickValues(data);
        } else {
          generator = d3.axisLeft(scale).tickValues(data);
        }
        return [generator, scale];
      } else {
        // eslint-disable-next-line
        let startValue = d3.min(data);
        let endValue = d3.max(data);
        let percentileValue = (endValue - startValue) / 10;
        startValue -= percentileValue;
        endValue += percentileValue;

        let scale = d3
          .scaleLinear()
          .domain([startValue, endValue])
          .range(range);

        //generator
        let generator = null;
        if (bottom) {
          generator = d3
            .axisBottom(scale)
            .tickValues(
              d3.range(startValue, endValue, (endValue - startValue) / 5)
            );
        } else {
          generator = d3
            .axisLeft(scale)
            .tickValues(
              d3.range(startValue, endValue, (endValue - startValue) / 5)
            );
        }
        return [generator, scale];
      }
    },
  },
  watch: {
    pandas_Dataframe: {
      // eslint-disable-next-line
      handler(newDf, oldDf) {
        this.pandas_Dataframe = newDf;
        this.SafeRender();
      },
    },
  },
};
</script>
<style >
/* #graph * {
  color: #fe0d1e;
} */
</style>