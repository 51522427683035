<template>
  <section class="hero is-light is-fullheight-with-navbar">
    <div class="hero-body" v-if="loading">
      <progress class="progress is-large is-info" max="100">60%</progress>
    </div>
    <div class="hero-body" v-else>
      <div class="container">
        <h1 class="title">welcome to {{ measureType.name }} measure</h1>

        <div v-if="message != null" class="notification is-danger is-light">
          <button class="delete" @click="error"></button>
          {{ message }}
        </div>
        <div class="box is-medium has-background-success is-bold">
          <h1 class="title">axis</h1>
        </div>
        <axes
          v-bind:axis="pandasDFs_axis"
          v-bind:possibleAxis="pandasDFs_data.columns"
          v-bind:changeAxis="changeAxis"
          v-bind:update="update"
        />

        <valueOverTime
          v-bind:pandas_Dataframe="graphData"
          v-bind:axes="pandasDFs_axis"
          v-bind:title="measureType.name"
        />

        <div class="columns">
          <div class="column">
            <div class="field has-addons">
              <div class="control">
                <input
                  class="input"
                  type="text"
                  placeholder="enter a name"
                  v-model="SaveName"
                />
              </div>
              <div class="control">
                <a class="button is-info" @click="save"> save </a>
              </div>
            </div>
          </div>

          <div class="column">
            <button class="button is-link" @click="exportToPdf">
              export graph
            </button>
          </div>
          <div class="column">
            <download-csv :data="selectedData" :name="dataFile">
              <button class="button is-info">export to csv</button>
            </download-csv>
          </div>
          <div class="column">
            <div class="select">
              <select v-model="selectedProcess">
                <option disabled value="">select a process</option>
                <option v-for="proces in processArray" v-bind:key="proces">
                  {{ proces.name }}
                </option>
              </select>
            </div>
            <button class="button is-link" @click="process">process</button>
          </div>
        </div>
        <!--     {{ pandasDFs_axis }} -->
        <div class="box is-medium has-background-success is-bold">
          <h1 class="title">filter</h1>
        </div>
        <filtre
          v-bind:pandas_Dataframes="pandasDFs_filter"
          v-bind:changeSelection="changeFilter"
        />

        <!--   {{ filter }} -->
        <div class="box is-medium has-background-success is-bold">
          <h1 class="title">data selected</h1>
        </div>
        <div class="datas">
          <table class="table is-bordered is-striped is-narrow is-hoverable">
            <thead>
              <th
                v-for="column_name in pandasDFs_data.columns"
                v-bind:key="column_name"
              >
                {{ column_name }}
              </th>
              <th>remove</th>
            </thead>
            <tbody>
              <tr v-for="(data, index) in selectedData" v-bind:key="index">
                <td v-for="d in data" v-bind:key="d">{{ d }}</td>
                <td>
                  <button
                    class="button is-info"
                    @click="removeFromSelectedData(index)"
                  >
                    remove
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <uniqueTable
            v-bind:pandas_Dataframe="pandasDFs_data"
            v-bind:changeSelectedData="changeSelectedData"
            v-bind:selectedDataIndex="selectedDataIndex"
            v-bind:filter="filter"
            v-bind:IsFilterChange="IsFilterChange"
          /> -->
        </div>
        <div class="box is-medium has-background-success is-bold">
          <h1 class="title">data not selected</h1>
        </div>
        <div class="datas">
          <table class="table is-bordered is-striped is-narrow is-hoverable">
            <thead>
              <th
                v-for="column_name in pandasDFs_data.columns"
                v-bind:key="column_name"
              >
                {{ column_name }}
              </th>
              <th>add</th>
            </thead>
            <tbody>
              <tr v-for="(data, index) in notSelectedData" v-bind:key="index">
                <td v-for="d in data" v-bind:key="d">{{ d }}</td>
                <td>
                  <button
                    class="button is-info"
                    @click="removeFromNotSelectedData(index)"
                  >
                    add
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <uniqueTable
            v-bind:pandas_Dataframe="pandasDFs_data"
            v-bind:changeSelectedData="changeSelectedData"
            v-bind:selectedDataIndex="selectedDataIndex"
            v-bind:filter="filter"
            v-bind:IsFilterChange="IsFilterChange"
          /> -->
        </div>
        <!--    {{ selectedData }} -->
      </div>
    </div>
  </section>
</template>


<script>
import valueOverTime from "../components/valueOverTime";
//import selectedTable from "../components/pandasToTable/selectedTable";
//import uniqueTable from "../components/pandasToTable/uniqueTable";
import filtre from "../components/pandasToTable/filter";
import axes from "../components/pandasToTable/axes";
const utils = require("../services/utils.service");
var d3 = require("d3");
var saveSvgAsPng = require("save-svg-as-png");
import JsonCSV from "vue-json-csv";

export default {
  name: "preview",
  components: {
    valueOverTime,
    //  selectedTable,
    //uniqueTable,
    filtre,
    axes,
    "download-csv": JsonCSV,
  },
  props: {
    storages: Array,
    axis: Object,
  },
  data: function () {
    return {
      measureTypeID: this.$route.params.id,
      idAnalyse: this.$route.params.idAnalyse,
      documentId: this.$route.params.documentId,
      measureType: null,
      instrumentId: null,
      dataFile: "my_export.csv",
      selectedData: [],
      selectedDataIndex: [],
      message: null,
      filter: [],
      IsFilterChange: false,
      pandasDFs_axis: {},
      pandasDFs_data: {},
      pandasDFs_filter: [],
      graphData: {},
      SaveName: "",
      loading: true,
      processArray: [],
      selectedProcess: "",
      notSelectedData: [],
    };
  },
  created: function () {
    this.$store
      .dispatch("measures/getMeasureTypeForAnalysePageById", {
        id: this.measureTypeID,
      })
      .then((params) => {
        this.measureType = { name: params[0].name, id: params[0].id };
        this.instrumentId = params[0].instrumentId;
      })
      .catch((err) =>
        this.$fire({
          title: "error",
          text: `${err.message} : ${err.stack}`,
          type: "warning",
        })
      );

    if (this.idAnalyse != -1) {
      this.$store
        .dispatch("measures/getAllDatas", {
          measureId: this.idAnalyse,
        })
        .then((datas) => {
          this.storages = datas;
        });
      this.$store
        .dispatch("measures/getAxis", {
          measureId: this.idAnalyse,
        })
        .then((axis) => {
          this.axis = {};
          this.axis["axe_x"] = axis[0].Xaxes;
          this.axis["axe_y"] = axis[0].Yaxes;
          this.axis["categorie"] = axis[0].Categorie;
        });
    }

    this.getParams();
    this.getProcess();
  },
  methods: {
    async getProcess() {
      await this.$store
        .dispatch("measures/getProcess", {
          measureTypeID: this.measureTypeID,
        })
        .then((process) => {
          this.processArray = process;
        })
        .catch((err) => {
          this.$fire({
            title: "error",
            text: `${err.message} : ${err.stack}`,
            type: "warning",
          });
        });
    },

    async getParams() {
      await this.$store
        .dispatch("measures/getPreviewParams", {
          measureTypeID: this.measureTypeID,
          documentId: this.documentId,
        })
        .then(
          (params) => {
            this.pandasDFs_filter = params[0];
            this.pandasDFs_data = params[1];
            this.pandasDFs_axis = params[2];
            this.loading = false;
            for (const index in this.pandasDFs_data.columns) {
              if (
                this.pandasDFs_data.columns[index]
                  .toLowerCase()
                  .match(/date/g) != null
              ) {
                for (let measure of this.pandasDFs_data.data) {
                  measure[index] = utils.TOString(measure[index]);
                }
                for (let value of this.pandasDFs_filter[index].data) {
                  value[0] = utils.TOString(value[0]);
                }
              }
            }
            this.update();
          },
          (error) => {
            this.message = error;
          }
        );
      if (this.storages != undefined) {
        for (const id of this.storages) {
          for (const measure of this.pandasDFs_data.data) {
            if (measure[0] == id.idStorage) {
              this.selectedData.push(measure);
            }
          }
        }

        this.storages = [];
        this.changeSelectedData(this.selectedData);
        if (this.axis != undefined) {
          this.pandasDFs_axis = this.axis;
        }
        this.notSelectedData = this.pandasDFs_data.data;
      }
    },
    exportToPdf() {
      saveSvgAsPng.saveSvgAsPng(document.getElementById("svg"), "diagram.png");
    },
    error() {
      this.$fire({
        title: "Warning",
        text: "close the windows will not resolve the error",
        type: "warning",
      });
    },
    changeSelectedData(selection) {
      this.selectedData = selection;
      this.update();
    },
    changeFilter(filter) {
      this.filter = filter;
      this.applyFilter();
    },
    update() {
      this.graphData = {
        columns: this.pandasDFs_data.columns,
        data: this.selectedData,
      };
    },
    removeFromSelectedData(index) {
      this.notSelectedData.push(this.selectedData[index]);
      this.selectedData.splice(index, 1);
      this.update();
    },
    removeFromNotSelectedData(index) {
      this.selectedData.push(this.notSelectedData[index]);
      this.notSelectedData.splice(index, 1);
      this.update();
    },
    applyFilter() {
      this.selectedData = [];
      this.notSelectedData = [];
      for (const indexFilter in this.filter) {
        if (this.filter[indexFilter].length > 0) {
          for (const indexNumber of this.filter[indexFilter]) {
            for (const datas of this.pandasDFs_data.data) {
              if (
                datas[indexFilter] ==
                this.pandasDFs_filter[indexFilter].data[indexNumber]
              ) {
                this.selectedData.push(datas);
              } else {
                this.notSelectedData.push(datas);
              }
            }
          }
        }
      }
      this.update();
    },
    changeAxis(newAxis) {
      this.pandasDFs_axis = newAxis;
      this.update();
    },
    save() {
      if (this.SaveName == "") {
        this.$fire({
          title: "enter a name",
          text: "you need to enter a name to save",
          type: "warning",
        });
      } else {
        let indexId = this.pandasDFs_data.columns.indexOf("id");
        let idMeasure = [];
        for (let measure of this.selectedData) {
          idMeasure.push(measure[indexId]);
        }
        this.$store
          .dispatch("measures/save", {
            idStorages: idMeasure,
            name: this.SaveName,
            axis: this.pandasDFs_axis,
            idmeasureType: this.measureType.id,
            instrumentID: this.instrumentId,
          })
          .then(this.$alert("analyses saved"))
          .catch((err) => {
            if (err.message.match(/409/g).length > 0) {
              this.$confirm("value will be erase continue ?").then(
                this.$store.dispatch("measures/save", {
                  idStorages: idMeasure,
                  name: this.SaveName,
                  axis: this.pandasDFs_axis,
                  idmeasureType: this.measureType.id,
                  instrumentID: this.instrumentId,
                  force: true,
                })
              );
            } else {
              this.$fire({
                title: "error",
                text: `${err.message} : ${err.stack}`,
                type: "warning",
              });
            }
          });
      }
    },
    process() {
      this.$store
        .dispatch("measures/process", {
          fileName: this.processName,
          axis: this.pandasDFs_axis,
          data: this.pandasDFs_data,
          idmeasureType: this.measureType.id,
          scriptName: this.selectedProcess,
        })
        .catch((err) => {
          {
            this.$fire({
              title: "something goes wrong :",
              text: err.error,
              type: "error",
            });
          }
        });
    },

    //-----------------------------

    // Download solution
    getDownloadURL(svg, callback) {
      var canvas;
      var source = svg.parentNode.innerHTML;
      var image = d3
        .select("body")
        .append("img")
        .style("display", "none")
        .attr("width", this.width)
        .attr("height", this.height)
        .node();

      image.onerror = function () {
        callback(new Error("An error occurred while attempting to load SVG"));
      };
      image.onload = function () {
        canvas = d3
          .select("body")
          .append("canvas")
          .style("display", "none")
          .attr("width", this.width)
          .attr("height", this.height)
          .node();

        var ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0);
        var url = canvas.toDataURL("image/png");

        d3.selectAll([canvas, image]).remove();

        callback(null, url);
      };
      image.src = "data:image/svg+xml," + encodeURIComponent(source);
    },

    updateDownloadURL(svg, link) {
      this.getDownloadURL(svg, function (error, url) {
        if (!error) {
          link.href = url;
        }
      });
    },
  },
};
</script>

<style >
.datas {
  overflow-x: auto;
  height: 250px;
  overflow: auto;
}
.datas table {
  white-space: nowrap;
}
</style>