<template>
  <div class="mainteance">
    <div class="columns is-centered">
      <div class="column is-narrow">
        <div class="field has-addons is-centered">
          <label class="label is-medium">new measure type :</label>

          <p class="control">
            <input
              class="input"
              type="text"
              placeholder="enter a name"
              v-model="measureType"
            />
          </p>
          <p class="control">
            <span class="select">
              <select v-model="valueTransfoScript">
                <option disabled selected="true" value="">
                  select transformation script
                </option>
                <option
                  v-for="optionScriptType in optionScriptTypes"
                  v-bind:key="optionScriptType.text"
                  v-bind:value="optionScriptType.value"
                >
                  {{ optionScriptType.text }}
                </option>
              </select>
            </span>
          </p>
          <p class="control">
            <a class="button is-link" @click="createMeasureType"> create </a>
          </p>
        </div>
      </div>
    </div>
    <div class="columns is-two-fifths is-centered">
      <div class="column is-narrow">
        <div class="field has-addons is-centered">
          <label class="label is-medium"
            >link measure type and instrument :</label
          >
          <p class="control">
            <span class="select">
              <select v-model="valueMeasureType">
                <option disabled selected="true" value="">
                  select a measure Type
                </option>
                <option
                  v-for="optionMeasureType in optionMeasuresType"
                  v-bind:key="optionMeasureType.text"
                  v-bind:value="optionMeasureType.value"
                >
                  {{ optionMeasureType.text }}
                </option>
              </select>
            </span>
          </p>
          <p class="control">
            <span class="select">
              <select v-model="valueInstrument">
                <option disabled selected="true" value="">
                  select an instrument
                </option>
                <option
                  v-for="optionInstrument in optionInstruments"
                  v-bind:key="optionInstrument.text"
                  v-bind:value="optionInstrument.value"
                >
                  {{ optionInstrument.text }}
                </option>
              </select>
            </span>
          </p>
          <p class="control">
            <a class="button is-link" @click="linkMeasureTypeIntrument">
              link
            </a>
          </p>
        </div>
      </div>
    </div>
    <div class="columns is-two-fifths is-centered">
      <div class="column is-narrow">
        <div class="field has-addons is-centered">
          <label class="label is-medium"
            >link measure type and analyses script (process) :</label
          >
          <p class="control">
            <span class="select">
              <select v-model="valueMeasureTypeFoScript">
                <option disabled selected="true" value="">
                  select a measure Type
                </option>
                <option
                  v-for="optionMeasureType in optionMeasuresType"
                  v-bind:key="optionMeasureType.text"
                  v-bind:value="optionMeasureType.value"
                >
                  {{ optionMeasureType.text }}
                </option>
              </select>
            </span>
          </p>
          <p class="control">
            <span class="select">
              <select v-model="valueScript">
                <option disabled selected="true" value="">
                  select a script
                </option>
                <option
                  v-for="optionScript in optionScripts"
                  v-bind:key="optionScript.text"
                  v-bind:value="optionScript.value"
                >
                  {{ optionScript.text }}
                </option>
              </select>
            </span>
          </p>
          <p class="control">
            <a class="button is-link" @click="linkMeasureTypeScript"> link </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "Maintenance",
  data: function () {
    return {
      optionScriptTypes: [],
      optionMeasuresType: [],
      optionInstruments: [],
      optionScripts: [],
      valueTransfoScript: "",
      valueMeasureType: "",
      valueMeasureTypeFoScript: "",
      valueInstrument: "",
      measureType: "",
      valueScript: "",
    };
  },
  created() {
    this.getTransfoType();
    this.getAllType();
    this.getAllInstrument();
    this.getAllScript();
  },
  methods: {
    async getTransfoType() {
      await this.$store
        .dispatch("measures/getTransfoScript")
        .then((transfoScripts) => {
          for (const type of transfoScripts) {
            this.optionScriptTypes.push({ text: type.name, value: type.id });
          }
        });
    },
    async getAllScript() {
      await this.$store.dispatch("measures/getAllScript").then((scripts) => {
        for (const script of scripts) {
          this.optionScripts.push({ text: script.name, value: script.id });
        }
      });
    },
    async getAllType() {
      this.optionMeasuresType = [];
      await this.$store
        .dispatch("measures/getMeasureType")
        .then((measuresType) => {
          for (const measureType of measuresType) {
            this.optionMeasuresType.push({
              text: measureType.name,
              value: measureType.id,
            });
          }
        });
    },
    async createMeasureType() {
      var vm = this;
      this.$store
        .dispatch("measures/addMeasureType", {
          name: this.measureType,
          TransfoScriptId: this.valueTransfoScript,
        })
        .then(function () {
          vm.getAllType();
        })
        .catch((err) => {
          this.$fire({
            title: "something went wrong :",
            text: err.error,
            type: "error",
          });
        });
    },

    async linkMeasureTypeIntrument() {
      this.$store.dispatch("measures/linMeasureInstrument", {
        measureId: this.valueMeasureType,
        instrumentId: this.valueInstrument,
      });
    },

    async linkMeasureTypeScript() {
      this.$store.dispatch("measures/linMeasureScript", {
        measureId: this.valueMeasureTypeFoScript,
        scriptId: this.valueScript,
      });
    },

    async getAllInstrument() {
      await this.$store
        .dispatch("measures/getAllInstrument")
        .then((instruments) => {
          for (const instrument of instruments) {
            this.optionInstruments.push({
              text: instrument.name,
              value: instrument.id,
            });
          }
        });
    },
  },
};
</script>