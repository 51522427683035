<template>
  <div class="Result Creation">
    <div class="container is-centered">
      <label class="label">select you're script</label>
      <div class="select is-primary">
        <select v-model="scriptSelected" @change="selectScript(scriptSelected)">
          <option dissabled value>select a script</option>
          <option
            v-for="script in scripts"
            v-bind:key="script.name"
            v-bind:value="script"
          >
            {{ script.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="column is-half is-offset-one-quarter">
      <div v-if="scriptSelected" class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Name</label>
        </div>
        <div class="field-body">
          <div class="field has-addons has-addons-centered">
            <p class="control">
              <input
                class="input"
                type="text"
                v-model="measureName"
                placeholder="name of you're mesasure"
              />
            </p>
            <p class="control">
              <a class="button is-link" @click="send()">send</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResultCreation",
  props: {
    documents: Array,
  },
  data: function () {
    return {
      typeList: {},
      scripts: [],
      scriptSelected: {},
      measureName: "",
    };
  },
  created() {
    this.getScripts();
  },
  methods: {
    send() {

      let documentsID = [];
      for (let document of this.documents) {
        documentsID.push(document.id);
      }
      this.$store.dispatch("measures/createResult", {
        documentsID: documentsID,
        scriptID: this.scriptSelected.id,
        name: this.measureName,
      });
      setTimeout(this.$router.push("measure"), 1000);
    },
    getScripts() {
      this.$store.dispatch("measures/getScrips").then((scripts) => {
        this.scripts = scripts;
      });
    },
    selectScript(scriptID) {
      this.scriptSelected = scriptID;
    },
  },
};
</script>
