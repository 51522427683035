'use strict';


exports.UTCDate = function (UTCdate) {
    let UTC = new Date(UTCdate);
    var options = { weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric" };
    return UTC.toLocaleDateString("en-GB", options)
}


exports.TOString = function (UTCdate) {
    let UTC = new Date(UTCdate);
    var options = { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric" };
    return UTC.toLocaleDateString("en-GB", options)
}
