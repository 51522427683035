import Vue from 'vue'
import Vuex from 'vuex'
import { measures } from './measure.module'
import { auth } from './googleAuth.module'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    measures,
    auth
  }
})
