<template>
  <table class="table is-bordered is-striped is-narrow is-hoverable">
    <thead>
      <th></th>
      <th>{{ name.replace("RANGE-", "") }}</th>
      <th></th>
    </thead>
    <tbody>
      <tr>
        <th>information</th>
        <td>
          min value :<br />
          {{ minValue }}
        </td>

        <td>
          max value :<br />
          {{ maxValue }}
        </td>
      </tr>
      <tr>
        <td>
          <button
            class="button"
            @click="applyFunction(index, min, max)"
          >
            apply range
          </button>
        </td>
        <td>
          <div class="control">
            <input
              class="input"
              type="text"
              placeholder="set min"
              v-model="min"
            />
          </div>
        </td>
        <td>
          <div class="control">
            <input
              class="input"
              type="text"
              placeholder="set max"
              v-model="max"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>


<script>
export default {
  name: "rangeFilterTable",
  props: {
    name: String,
    minValue: String,
    maxValue: String,
    applyFunction: Function,
    index: Number,
  },
};
</script>