<template>
  <nav class="navbar is-info" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link to="/">
        <img
          width="100px"
          height="27px"
          src="https://h.glass/wp-content/themes/bwap-theme/images/logo_full.svg"
          alt="H.Glass"
          style="margin-left: 15px; margin-top: 15px"
        />
      </router-link>
    </div>

    <div id="navbar-start" class="navbar-menu">
      <router-link to="/" class="navbar-item" style="color: #ffffff"
        >Documents</router-link
      >
      <router-link to="/measure" class="navbar-item" style="color: #ffffff"
        >Analysis</router-link
      >
    </div>
    <div
      id="navbar-right"
      class="navbar-menu"
      style="margin-left: 15px; margin-top: 15px"
    >
      <div class="navbar-end" v-if="this.loggedIn">
        <a
          class="navbar-item"
          href="https://wiki.h.glass/doku.php?id=equipment:software_platforms:measurement_platform:measurement_platform"
          target="_blank"
        >
          Wiki
        </a>

        <div class="navbar-item">
          <p>connected as {{ this.user.Ad }}</p>
        </div>
        <div class="navbar-item">
          <div class="buttons">
            <a class="button is-light" v-on:click="logOut"> Log out </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "theNavBarComponent",
  data: function () {
    return {
      user: {},
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.user = this.$store.state.auth.user;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/");
    },
  },
  watch: {
    // eslint-disable-next-line 
    loggedIn(newLog, oldlog) {
      if (newLog) {
        this.user =this.$store.state.auth.user;
      } else {
        this.user = {};
      }
    },
  },
};
</script>

<style scoped>
.router-link-active:hover,
.navbar-item:hover,
.navbar-link.is-active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
a.navbar-item.is-active,
a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover {
  background: hsla(0, 0%, 100%, 0.2);
  color: white;
}
</style>

