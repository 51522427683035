<template>
  <section class="hero">
    <div class="hero-body">
      <h1 class="title">docs</h1>
      <h2 class="subtitle">
        here you can find all docs push on the drive. Select you're document ,
        transform the data with the measure type and launch the desired script
        to create a measure.
      </h2>
      <div v-if="this.message != ``">
        {{ message }}
      </div>
      <div class="container"></div>
      <div class="container">
        <div class="columns">
          <div class="column">
            <div class="columns">
              <div class="column is-narrow">
                <label class="label">Document by page</label>
              </div>
              <div class="column is-narrow">
                <span class="select is-small is-rounded">
                  <select
                    v-model="sizePage"
                    @change="changeChunkSize(sizePage)"
                  >
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                    <option>100</option>
                    <option>500</option>
                    <option>1000</option>
                  </select>
                </span>
              </div>
            </div>
          </div>
          <div class="column" v-if="maxDocumentPage != undefined">
            <paginate
              :page-count="maxDocumentPage"
              :page-range="3"
              :margin-pages="2"
              :click-handler="changePage"
              :prev-text="'Prev'"
              :next-text="'Next'"
              :container-class="'pagination'"
              :page-link-class="'pagination-link'"
              :prev-link-class="'pagination-previous'"
              :next-link-class="'pagination-next'"
              :active-class="'is-current'"
              :disabled-class="'-ellipsis'"
            >
            </paginate>
          </div>
        </div>
        <table class="table is-fullwidth is-bordered is-striped">
          <thead>
            <tr>
              <th v-for="column in columnsName" v-bind:key="column">
                {{ column }}
                <font-awesome-icon
                  v-if="orderId.includes(`${column}`)"
                  icon="caret-down"
                  @click="orderBy(`${column}`, false)"
                />
                <font-awesome-icon
                  v-else-if="column != `measure Type`"
                  icon="caret-up"
                  @click="orderBy(`${column}`, true)"
                />
              </th>
              <!-- <th>selection</th> -->
            </tr>
          </thead>
          <tbody>
            <Search
              v-bind:callback="getMeasure"
              v-bind:columns="columnsName"
              v-bind:likes="likes"
              v-bind:changePage="changePage"
            />
            <tr v-for="document in docs" v-bind:key="document.id">
              <td v-for="column in columnsName" v-bind:key="column">
                <div v-if="column == `measure Type`">
                  <MeasureType
                    v-bind:documentId="document.id"
                    v-bind:instrumentId="document.instrumentId"
                  />
                </div>
                <div v-else>
                  {{ document[column] }}
                </div>
              </td>
              <!--  <td>
                <input
                  type="checkbox"
                  v-bind:id="document.id"
                  v-bind:value="document"
                  v-model="pickeddocument"
                /> 
                <label v-bind:for="document.id"></label>
              </td>-->
            </tr>
          </tbody>
        </table>
        <div class="container" v-if="pickeddocument.length > 0">
          <ResultCreation v-bind:docs="pickeddocument" />
        </div>
      </div>
    </div>
    <div class="hero-body">
      <h1 class="title">maintenance</h1>
      <h2 class="subtitle">
        create new measure type link to a transformation script and other futur
        maintenance task
      </h2>
      <Maintenance />
    </div>
  </section>
</template>


<script>
var Paginate = require("vuejs-paginate");
import MeasureType from "../components/MeasureType";
import ResultCreation from "../components/ResultCreation";
import Search from "./search";
import Maintenance from "./maintenance";
const utils = require("../services/utils.service");

export default {
  name: "DocumentTable",
  components: {
    MeasureType,
    ResultCreation,
    Search,
    Maintenance,
    Paginate,
  },
  data: function () {
    return {
      columnsName: ["status", "name", "instrument", "measure Type", "date"],
      sizePage: 10,
      page: 0,
      docs: [],
      pickeddocument: [],
      ordering: ["date", "name", "instrument"],
      orderId: [],
      likes: [],
      message: "",
      maxDocumentPage: 0,
      maxDocument: 0,
    };
  },
  beforeMount: function () {
    // eslint-disable-next-line
    for (let column in this.columns) {
      this.likes.push("");
    }
    this.countDocument({});
    this.map = new Map();
    this.map.set("date", false);
    this.map.set("name", true);
    this.map.set("instrument", true);

    this.getMeasure();
  },
  methods: {
    async forceReaload() {
      this.$store.dispatch("measures/forceReload");
    },
    async countDocument(liking) {
      await this.$store
        .dispatch("measures/countDocument", { likes: liking })
        .then((count) => {
          this.maxDocument = count["COUNT(*)"];
          count = Math.ceil(this.maxDocument / this.sizePage);
          this.maxDocumentPage = Math.ceil(this.maxDocument / this.sizePage);
        });
      // .catch((err) => console.log(err));
    },
    changeChunkSize(size) {
      this.sizePage = size;
      this.maxDocumentPage = Math.ceil(this.maxDocument / this.sizePage);
      this.getMeasure();
    },
    async changePage(page) {
      this.page = page - 1;
      this.getMeasure();
    },
    async getMeasure() {
      this.order = [];
      let liking = {};
      for (let like in this.likes) {
        liking[this.columnsName[like]] = this.likes[like];
      }
      this.countDocument(liking);
      for (let priority of this.ordering) {
        this.order.push({ column: priority, ASC: this.map.get(priority) });
      }
      this.$store
        .dispatch("measures/getDocument", {
          page: this.page,
          sizePage: this.sizePage,
          order: this.order,
          likes: liking,
        })
        .then(
          (docs) => {
            this.docs = docs;
            for (let document of this.docs) {
              document.date = utils.UTCDate(document.date);
            }
          },
          (err) => {
            return Promise.reject(err);
          }
        );
      return Promise.resolve();
    },
    orderBy(column, asc) {
      //reset the page
      this.page = 0;
      // change the order for the column selected
      this.map.set(column, asc);
      if (asc) {
        this.orderId.push(column);
      } else {
        let index = this.orderId.findIndex((element) => element == column);
        this.orderId = this.orderId
          .slice(0, index)
          .concat(this.orderId.slice(index + 1, this.orderId.length));
      }
      let tmp = this.ordering[0];
      let index = this.ordering.findIndex((element) => element == column);
      this.ordering[0] = this.ordering[index];
      this.ordering[index] = tmp;
      this.getMeasure();
    },
    createMeasure(pickeddocument) {
      this.$router.push({
        name: "measureCreation",
        params: { docs: pickeddocument },
      });
    },
  },
};
</script>


