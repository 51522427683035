import store from '../store/index'; // path to your Vuex store


export default function authHeader() {
    if (store.state.auth.user != null) {
        let token = store.state.auth.user.token;
        return { Authorization: token }
    } else {
        let token = JSON.parse(localStorage.getItem("user")).token;
        return { Authorization: token }
    }
}