<template>
  <div class="columns is-vcentered">
    <div class="column" v-for="(value, axes) in axis" v-bind:key="axes">
      <h2 class="subtitle">{{ axes }}</h2>
      <select v-model="axis[axes]" @change="update">
        <option>{{ value }}</option>
        <option
          v-for="axes in possibleAxisWithoutAvalue(value)"
          v-bind:key="axes"
        >
          {{ axes }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "axes",
  props: {
    axis: Object,
    possibleAxis: Array,
    changeAxis: Function,
    update: Function,
  },
  data: function () {
    return {
      selection: [],
    };
  },
  methods: {
    possibleAxisWithoutAvalue(value) {
      let result = [];
      for (let axes of this.possibleAxis) {
        if (axes != value) {
          result.push(axes);
        }
      }
      return result;
    },
  },
};
</script>