<template>
  <table class="table is-bordered is-striped is-narrow is-hoverable">
    <thead>
      <th
        v-for="column_name in pandas_Dataframe.columns"
        v-bind:key="column_name"
      >
        {{ column_name }}
      </th>
      <th>
        selection
        <input
          v-if="filter != undefined"
          type="checkbox"
          v-model="selectAll"
          v-bind:true-value="true"
          v-bind:false-value="false"
        />
      </th>
    </thead>
    <tr v-for="(data, index) in pandas_Dataframe.data" v-bind:key="index">
      <td>{{ data[0] }}</td>
      <td>
        <label class="checkbox">
          <input
            type="checkbox"
            v-bind:id="index"
            v-bind:value="index"
            v-model="selected"
        /></label>
      </td>
    </tr>
    <tbody></tbody>
  </table>
</template>
 
<script>
export default {
  name: "uniqueTable",
  props: {
    pandas_Dataframe: Object,
    changeSelectedData: Function,
  },
  data: function () {
    return {
      selected: [],
    };
  },
  methods: {
    getData(index) {
      return this.pandas_Dataframe.data[index];
    },
  },
  created() {},
  watch: {
    // eslint-disable-next-line
    selected: function (newSelection, oldSelection) {
      this.changeSelectedData(newSelection);
    },
  },
};
</script>

